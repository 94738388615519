export default {
  id: {
    type: Number,
    visibility: false
  },
  name: {
    label: 'Nombre',
    type: String
  },
  uniqueId: {
    label: 'Identificador',
    type: String
  },
  _location: {
    label: 'Ubicación',
    type: String,
    location: true,
    fields: ['latitude', 'longitude']
  },
  longitude: {
    label: 'Longitud',
    type: Number,
    visibility: false
  },
  latitude: {
    label: 'Latitud',
    type: Number,
    visibility: false
  },
  groupId: {
    label: 'Grupo',
    type: Number,
    optional: true,
    model: 'groups',
    module: 'sensor',
    autocomplete: false
  },
  separator_datos_del_negocio: {
    label: 'Datos del negocio',
    type: String,
    separator: true,
    optional: true,
  },
  fiscalName: {
    label: 'Nombre fiscal',
    type: String,
    optional: true,
  },
  city: {
    label: 'Localidad',
    type: String,
  },
  address: {
    label: 'Dirección',
    type: String,
    optional: true,
  },
  phone: {
    label: 'Teléfono',
    type: String,
    optional: true,
  },
  separator_datos_del_contacto: {
    label: 'Datos del contacto',
    type: String,
    separator: true,
    optional: true,
  },
  contact: {
    label: 'Nombre del contacto',
    type: String,
    optional: true,
  },
  contactLastname1: {
    label: 'Apellido paterno',
    type: String,
    optional: true,
  },
  contactLastname2: {
    label: 'Apellido materno',
    type: String,
    optional: true,
  },
  contactJob: {
    label: 'Puesto',
    type: String,
    optional: true,
  },
  contactPhone: {
    label: 'Teléfono',
    type: String,
    optional: true,
  },
  contactEmail: {
    label: 'Correo electronico',
    type: String,
    optional: true,
  },
  separator_datos_del_dvr: {
    label: 'Datos del DVR',
    type: String,
    separator: true,
    optional: true,
  },
  dvrBrand: {
    label: 'Marca',
    type: String,
    optional: true,
  },
  dvrModel: {
    label: 'Modelo',
    type: String,
    optional: true,
  },
  numberOfCameras: {
    label: 'Número de camaras',
    type: Number,
    optional: true,
    readonly: true
  },
  attributes: {
    label: 'Camaras',
    type: Array,
    title: (item) => {
      return item.name
    },
    optional: true,
    onChange: (val, field) => {
      field.numberOfCameras.value = val ? val.length : 0
      field.attributes.value.forEach((attr, i) => {
        attr.name = 'dev' + i
      })
    }
  },
  'attributes.$': {
    type: {
      server: {
        label: 'Servidor',
        type: String,
        model: 'v2/video-servers',
        autocomplete: false,
      },
      name: {
        label: 'Nombre',
        type: String,
        visibility: false
      },
      ip: {
        label: 'Dirección IP',
        type: String,
      },
      user: {
        label: 'Usuario',
        type: String,
      },
      password: {
        label: 'Password',
        type: String,
      },
      stream: {
        label: 'Stream',
        type: String,
      }
    }
  }
};
